<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
    }

    .page-break {
        page-break-before: always;
        counter-reset: page;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

th.no-border {
    border: none;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

.info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}

#page-counter {
    display: table-footer-group;
}

#page-counter:after {
    counter-increment: page;
    content: " " counter(page) " of " counter(page);
}

.signature-table {
    page-break-inside: avoid;
    margin-top: 20px;
}
</style>

<template>
    <div>
        <div class="class-break">
            <table class="" style="width: 100%;">
                <thead>
                    <tr class="">
                        <th colspan="7">
                            <div class="title">
                                <p>PICKING LIST - SUPPLIER RETURN</p>
                                <p>{{ data.company.Name }}</p>
                            </div>
                        </th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">GRR Document</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ data.grr_code }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1" colspan="2"></th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Cabang</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ data.warehouse.code }} - {{ data.warehouse.name }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print date</th>
                        <th class="py-0 w-1" colspan="2">: {{ dateNow }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Return Date</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(data.return_date) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print time</th>
                        <th class="py-0 w-1" colspan="2">: {{ timeNow }}</th>
                    </tr>
                </thead>
            </table>
            <br>
            <table class="" style="width: 100%;">
                <thead>
                    <tr style="border-top: 1px dashed; border-bottom: 1px dashed">
                        <th style="vertical-align: middle; text-align: left">Sku Code</th>
                        <th style="vertical-align: middle; text-align: left">Sku Description</th>
                        <th style="vertical-align: middle; text-align: left">Batch</th>
                        <th style="vertical-align: middle; text-align: left">Exp. Date</th>
                        <th style="vertical-align: middle; text-align: left">Quantity</th>
                        <th style="vertical-align: middle; text-align: left">Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, idxtr) in data.handover_lines" :key="idxtr">
                        <td class="py-1" style="padding-left: 10px;">{{ tr.item_unit.sku_code }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.item_unit.item_name }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.batch_external }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ formatDate(tr.exp_date) }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.qty }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.item_unit.name }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr style="border-top: 1px dashed">
                        <td colspan="6"></td>
                    </tr>
                </tfoot>
            </table>
            <br/>
            <table class="remove_border signature-table" style="page-break-before: auto">
                <tr>
                    <th style="text-align: center">Dibuat oleh</th>
                    <th style="text-align: center">Disiapkan oleh</th>
                    <th style="text-align: center">Diterima oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                </tr>
                <tr>
                    <th style="text-align: center">Admin Logistic</th>
                    <th style="text-align: center">Picker</th>
                    <th style="text-align: center">Checker</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPickingList",
    data() {
        return {
            // logoSDN: logoSDN,
            id: null,
            handoverIds: null,
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            showInfo: false,
            data: {
                handover_lines: [],
                warehouse: {},
            },
            printCounter: 0,
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.print()
        }
    },
    methods: {
        async print() {
            const tempTitle = document.title
            await this.getData()
            await window.print()
            document.title = tempTitle
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get("api/wms/v1/wms/outbound/supplier-return/handover/"+this.id);
            if (resp.code == 200) {
                console.log(resp.data)
                this.data = resp.data
                const now = new Date()
                this.dateNow = this.formatDate(now.setHours(now.getHours() + 7));
                this.timeNow = this.formatTime(now);
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("YYYY-MM-DD");
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("hh:mm:ss");
            };
        },
    },
}
</script>